import { ReactNode } from "react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Swiper as DefaultSwiper } from "swiper/react/swiper-react.js";
import "./swiper.css";

export default function Swiper({ children }: { children: ReactNode }) {
  return (
    <DefaultSwiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={1}
      spaceBetween={10}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1080: {
          slidesPerView: 3,
          spaceBetween: 110,
        },
      }}
      coverflowEffect={{
        rotate: 30,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      }}
      pagination={{
        clickable: true,
        bulletActiveClass: "bullet_class_active",
      }}
      navigation={true}
      modules={[EffectCoverflow, Navigation, Pagination]}
      className="swiper_container"
    >
      {children}
    </DefaultSwiper>
  );
}
