import "./footer.css";

function Footer() {
  return (
    <div className="footer_container section_padding">
      <p>Martín Navarro © 2022</p>
    </div>
  );
}

export default Footer;
