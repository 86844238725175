import "./card.css";
import VanillaTilt from "vanilla-tilt";
import { createRef, useLayoutEffect } from "react";

export default function Card({
  title,
  backText,
  backTextImg,
  paragraph,
  link,
}: {
  title: string;
  backText?: string;
  backTextImg?: string;
  paragraph?: string;
  link?: string;
}): JSX.Element {
  const cardRef = createRef<HTMLDivElement>();

  useLayoutEffect(() => {
    const elements = cardRef.current;
    if (elements != null && window.innerWidth >= 480) {
      VanillaTilt.init(elements, {
        max: 10,
        speed: 400,
        glare: true,
        "max-glare": 1,
      });
    }
  }, []);

  return (
    <div ref={cardRef} className="card-main">
      <div className="card-content">
        {!!backText && <h2>{backText}</h2>}
        {!!backTextImg && <i className={backTextImg}></i>}
        <h3>{title}</h3>
        {!!paragraph && <p>{paragraph}</p>}
        {!!link && <a href="#">{link}</a>}
      </div>
    </div>
  );
}
