import "./App.css";
import { Header, Who, Skills, Footer } from "./containers";
import { Navbar } from "./components";
import Projects from "./containers/projects";
import "swiper/swiper-bundle.min.css";
import Divider from "./components/divider";

function App() {
  return (
    <div className="App">
      <div className="normal_bg">
        <Navbar />
        <Header />
      </div>
      <Who />
      <Divider />
      <Skills />
      {/* <Projects /> */}
      <Footer />
    </div>
  );
}

export default App;
