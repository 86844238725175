import Card from "../../components/card";
import Spheres from "../../components/spheres";
import { SwiperSlide } from "swiper/react/swiper-react.js";
import Swiper from "../../components/swiper";
import "./skills.css";
import DividerFooter from "../../components/dividerfooter";

type SkillType = {
  title: string;
  backText?: string;
  backTextImg?: string;
  paragraph?: string;
  link?: string;
};

type SphereType = {
  top?: number;
  left?: number;
  height?: number;
  width?: number;
  timing?: number;
  color?: "blue" | "green" | "yellow" | "orange" | "purple" | "brown" | "red";
  form?:
    | "pentagon"
    | "square"
    | "circle"
    | "rhombus"
    | "triangle"
    | "trapezoid"
    | "nonacagon";
};

function Skills() {
  const skills: SkillType[] = [
    {
      title: "React",
      backTextImg: "devicon-react-original colored",
      paragraph:
        "Implementaciones para el desarrollo de páginas web como aplicaciones móviles",
    },
    {
      title: "Java",
      backTextImg: "devicon-java-plain colored",
      paragraph:
        "Conocimientos en Java reactivo con JavaRX y desarrollo backend hecho Spring Boot",
    },
    {
      title: "NodeJS",
      backTextImg: "devicon-nodejs-plain colored",
      paragraph: "Desarollo backend con Express y NodeJS usando Typescript",
    },
    {
      title: "MongoDB",
      backTextImg: "devicon-mongodb-plain colored",
      paragraph:
        "Armado de esquemas y modelos de base de datos así como indexación",
    },
    {
      title: "PHP",
      backTextImg: "devicon-php-plain colored",
      paragraph:
        "Aplicado a conexiones con AWS SQS y framework de Laravel para desarrollo backend",
    },
  ];

  const spheres: SphereType[] = [
    {
      width: 100,
      top: 70,
      left: 15,
      timing: 4,
      color: "blue",
      form: "pentagon",
    },
    {
      width: 30,
      top: 35,
      left: 10,
      timing: 2,
      color: "purple",
      form: "triangle",
    },
    {
      width: 70,
      top: 10,
      left: 20,
      timing: 3,
      color: "green",
      form: "square",
    },
    {
      width: 50,
      top: 40,
      left: 90,
      timing: 2,
      color: "yellow",
      form: "rhombus",
    },
    {
      width: 90,
      top: 75,
      left: 70,
      timing: 1,
      color: "orange",
      form: "circle",
    },
    {
      width: 90,
      top: 0,
      left: 80,
      timing: 4,
      color: "red",
      form: "nonacagon",
    },
  ];

  return (
    <section id="skills" className="skills_content section_padding_top">
      <div className="skills_header">
        <h1>Habilidades</h1>
      </div>
      {spheres.map((sphere, index) => (
        <Spheres
          key={index}
          topProp={sphere.top}
          leftProp={sphere.left}
          heightProp={sphere.height}
          widthProp={sphere.width}
          timingProp={sphere.timing}
          colorProp={sphere.color}
          formProp={sphere.form}
        />
      ))}
      <Swiper>
        {skills.map((skill, index) => (
          <SwiperSlide
            key={index}
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              title={skill.title}
              backText={skill.backText}
              backTextImg={skill.backTextImg}
              paragraph={skill.paragraph}
              link={skill.link}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <DividerFooter />
    </section>
  );
}

export default Skills;
