import { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import "./navbar.css";
import "animate.css";

const Menu = () => {
  return (
    <>
      <p>
        <a href="#home">Inicio</a>
      </p>
      <p>
        <a href="#skills">Habilidades</a>
      </p>
      <p>
        <a href="#proj">Proyectos</a>
      </p>
    </>
  );
};

function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navbar">
      <div className="navbar-links">
        <div className="navbar-links_logo animate__animated animate__fadeInLeft">
          <a href="#home">
            Inicio
            <span className="navbar-line"></span>
          </a>
        </div>
        <div className="navbar-links_logo animate__animated animate__fadeInDown">
          <a href="#skills">
            Habilidades
            <span className="navbar-line"></span>
          </a>
        </div>
        <div className="navbar-links_logo animate__animated animate__fadeInRight">
          <a href="#proj">
            Proyectos
            <span className="navbar-line"></span>
          </a>
        </div>
        {/* <div className="navbar-links_container">
          <Menu />
        </div> */}
      </div>
      <div className="navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#4ad66d"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#4ad66d"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar-menu_container-links">
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
