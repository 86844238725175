import { createRef, HTMLProps, useLayoutEffect, useRef } from "react";
import "./spheres.css";

export default function Spheres({
  widthProp,
  heightProp,
  topProp,
  leftProp,
  timingProp,
  colorProp,
  formProp,
  ...props
}: {
  widthProp?: number;
  heightProp?: number;
  topProp?: number;
  leftProp?: number;
  timingProp?: number;
  formProp?:
    | "pentagon"
    | "square"
    | "circle"
    | "rhombus"
    | "triangle"
    | "trapezoid"
    | "nonacagon";
  colorProp?:
    | "blue"
    | "green"
    | "yellow"
    | "orange"
    | "purple"
    | "brown"
    | "red";
} & HTMLProps<HTMLDivElement>): JSX.Element {
  const width = widthProp ?? 50;
  const height = heightProp ?? width;
  const top = topProp ?? 0;
  const left = leftProp ?? 0;
  const timingCheck = timingProp ?? 1;
  const timing = timingCheck <= 5 ? timingCheck : 1;
  const form = formProp ?? "circle";

  const circleRef = createRef<SVGCircleElement>();

  function positionTheDot() {
    const scrollPercentage =
      (document.documentElement.scrollTop + document.body.scrollTop) /
      (document.documentElement.scrollHeight -
        document.documentElement.clientHeight);

    const path = document.getElementById(
      "theMotionPath"
    ) as unknown as SVGGeometryElement;
    if (!path) return;
    const pathLen = path.getTotalLength();

    const pt = path.getPointAtLength(scrollPercentage * pathLen);

    // const dot = document.getElementById("dot");
    const circleDot = circleRef.current;
    if (!!circleDot)
      circleDot.setAttribute(
        "transform",
        "translate(" + pt.x + "," + pt.y + ")"
      );
  }

  window.addEventListener("scroll", positionTheDot);
  positionTheDot();

  return (
    <>
      <div
        {...props}
        className={`spheres_main spheres_form_${form} spheres_${colorProp} spheres_subpixel spheres_timing_${timing}`}
        style={{
          top: top + "%",
          left: left + "%",
          width,
          height,
        }}
      ></div>
      {/* <svg style={{
        width: "100vw",
        position: "absolute"
      }} viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <path d="M 0 0 C 16.6667 13.3333 30 40 50 40 C 70 40 100 50 110 70 C 140 110 176.6667 63.3333 210 60 C 230 60 230 40 250 30"
          stroke-width="0"
          fill="none"
          id="theMotionPath" />
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop stop-color="#4ad66d"></stop>
          <stop offset={50} stop-color="#78ffd6"></stop>
        </linearGradient>
        <circle
          fill="url(#linearColors)"
          className="spheres_main"
          ref={circleRef} cx="0" cy="0" r="5" />
      </svg> */}
    </>
  );
}
