import "./dividerfooter.css";

export default function DividerFooter() {
  return (
    <div className="divider_footer_container">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          className="divider_footer_shape_fill"
          d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"
        ></path>
      </svg>
    </div>
  );
}
