import "./who.css";
import TypeAnimation from "react-type-animation";
import logo from "../../assets/marty.jpg";
import { motion } from "framer-motion";
import "animate.css";

function Who(): JSX.Element {
  return (
    <section id="home" className="who_content section_padding">
      <div className="who_header">
        <h3 className="animate__animated animate__slideInLeft">Hola, soy</h3>
        <h1 className="animate__animated animate__slideInLeft">
          Martin Navarro
        </h1>
        <TypeAnimation
          cursor={true}
          sequence={[
            "Desarrollador",
            4000,
            "Autodidacta",
            2000,
            "Matefanático",
            2000,
            "Analista",
            2000,
            "De cierta forma diseñador",
            2000,
            "Lorem ipsum",
            2000,
            "Amante del café",
            2000,
            "Me gusta aplicar soluciones creativas",
            2000,
            "Interesado en arquitecturas de código",
            2000,
            "No leí Clean Code pero me gustaría",
            2000,
            "Detallista ante cualquier eror",
            2500,
            "...",
            1000,
            "error*",
            2000,
            "Cualquiera puede programar :)",
            2000,
            "Te invito a conocer mi página",
            2000,
          ]}
          wrapper="h2"
          repeat={Infinity}
          className="type_code"
        />
      </div>
      <div className="framer_container">
        <motion.div
          animate={{
            scale: [1, 2, 2, 1, 1, 2, 1, 0.5],
            rotate: [0, 0, 270, 270, 0],
            background: [
              "linear-gradient(#0077b6, #023e8a)",
              "linear-gradient(#f9c74f, #faa307)",
              "linear-gradient(#ff8a71, #ff4828)",
              "linear-gradient(#7b2cbf, #5a189a)",
              "linear-gradient(#4ad66d, #78ffd6)",
              "linear-gradient(#ddd, #282828)",
              "linear-gradient(#4ad66d, #78ffd6)",
              "linear-gradient(#7b2cbf, #5a189a)",
            ],
            borderRadius: [
              "20%",
              "20%",
              "50%",
              "50%",
              "20%",
              "10%",
              "30%",
              "40%",
            ],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1, 0.8, 0.5],
            repeat: Infinity,
            repeatDelay: 1,
          }}
        />
      </div>
      {/* <div className="who_img"><img src={logo} alt="martin" /></div> */}
    </section>
  );
}

export default Who;
